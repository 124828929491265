<template>
  <div class="user-top">
    <div class="top-filter">
      <div class="input-select">
        <TextSearchBar
          class="text-search"
          ref="testSearch"
          v-model="filterInput"
          :placeholder="$t('am_search_account')"
          @clean="clearInput"
          @search="onSearchAccount"
        />
        <!-- 角色 -->
        <div class="select-role">
          <!-- <Select
            v-model="selectRoles"
            effect="dark"
            :options="roles"
            :multiSelect="true"
            :hasFilter="selectRoles.length > 0"
            :placeholder="$t('account_role') /*角色*/"
            :class="{ disabled: roles.length <= 0 }"
          /> -->
          <ClassifySelect
            :title="null"
            :placeholder="null"
            :funName="$t('account_role' /*角色*/)"
            :disabled="roleOptions.length <= 0"
            v-model="selectRoles"
            :options="uiRoleOptions"
            :multiple="true"
            :effect="'dark'"
            :tooltipPlacement="'top'"
            @getFilterText="onGetRoleFilterText"
          />
        </div>
        <!-- 帳號狀態 -->
        <div class="select-status">
          <Select
            v-model="selectUseStates"
            effect="dark"
            :options="stateOptions"
            :multiSelect="true"
            :hasFilter="selectUseStates.length > 0"
            :placeholder="$t('account_status') /*帳號狀態*/"
          />
        </div>
        <!-- 公開/私有 -->
        <div class="select-more">
          <AccountFilter :filters="filters" @set="onGetFilters" />
        </div>
        <!-- 橡皮擦 -->
        <div
          class="eraser"
          :class="{ disabled: !needEraser }"
          @click="onCleanFilters"
        >
          <img src="@/assets/icons/erase-white.svg" alt="" />
        </div>
        <div class="filter-result">
          {{
            $t('account_filter_result', {
              count: filterResult >= 0 ? filterResult : 0
            }) /*篩選結果*/
          }}
        </div>
      </div>
      <!-- <div class="top-filter-right">
        <div class="filter-result">
          {{
            $t('account_filter_result', {
              count: filterResult >= 0 ? filterResult : 0
            }) /*篩選結果*/
          }}
        </div>
      </div> -->
    </div>
    <div class="top-edit" v-if="showEdit">
      <ToggleSwitch
        v-if="showEdit"
        ref="switch"
        :content="$t('account_multi_select') /*多選帳號*/"
        id="top-switch"
        :value="multiSelectShow"
        @switch="onSwitchMultiple"
      />
      <div class="create-account">
        <div class="create-account-content" @click="onCreateAccount">
          <i class="fas fa-plus"></i>
          <span>{{ $t('account_add') /*新增帳號*/ }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations /*, mapGetters*/ } from 'vuex'
import TextSearchBar from '@/components/tools/TextSearchBar.vue'
import Select from '@/components/AccountManagement/tools/Select.vue'
import AccountFilter from '@/components/AccountManagement/tools/AccountFilters.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import ClassifySelect from '@/components/Base/ClassifySelect.vue'
import { console /*, getAncestorList*/ } from '@/utils/lib'
import { all, userFilters, euUsedState, roleFilter } from '@/config/account'

export default {
  name: 'UserTop',
  components: {
    TextSearchBar,
    Select,
    AccountFilter,
    ToggleSwitch,
    ClassifySelect
  },
  data() {
    return {
      // isDev: console.get(),
      filters: JSON.parse(JSON.stringify(userFilters)),
      stateOptions: Object.keys(euUsedState).map((key) => {
        return {
          label: this.$t(`account_status_${key}`),
          value: `${key}`,
          icon: `user${
            euUsedState[key] === euUsedState.enabled ? '' : '_' + key
          }.svg`
        }
      }),
      multiSelectShow: this.editMode === 'multiSelect',

      roleFilterText: null
    }
  },
  props: ['showEdit'],
  computed: {
    // ...mapGetters(['getUserInfo']),
    ...mapState(['permissionV2', 'staff']),
    ...mapState('userinfo', [
      'groupId',
      'roleList',
      'filterText',
      'filterRoles',
      'filterUseStates',
      'filterPublic',
      'filterResult',
      'editMode',
      'groupTreeList',
      'userInfoList'
    ]),
    // ...mapGetters('userinfo', ['loggedUserInfo']),
    roleOptions() {
      if (!this.roleList) return []
      let options = []

      // 所有角色不過濾
      this.roleList.forEach((role) => {
        options.push({
          label: role.name,
          value: role.id,
          // icon,
        })
      })
      console.log(`[UserTop][roles] roleList`, this.roleList.length)
      return options
    },
    uiRoleOptions() {
      if (!this.roleFilterText) return this.roleOptions

      let options = [...this.roleOptions]
      const key = this.roleFilterText.toLowerCase()

      return roleFilter(key, options)
    },
    filterInput: {
      get() {
        return this.filterText
      },
      set(value) {
        this.updateFilterText(value)
      }
    },
    selectRoles: {
      get() {
        return this.filterRoles
      },
      set(value) {
        this.updateFilterRoles(value)
      }
    },
    selectUseStates: {
      get() {
        return this.filterUseStates
      },
      set(value) {
        this.updateFilterUseStates(value)
      }
    },
    needEraser() {
      if (
        this.filterText ||
        this.filterRoles.length > 0 ||
        this.filterUseStates.length > 0 ||
        this.filterPublic !== all
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations('userinfo', [
      'clearSelectedGroupList',
      'updateFilterText',
      'updateFilterRoles',
      'cleanFilterRoles',
      'updateFilterUseStates',
      'cleanFilterUseStates',
      'updateEditMode',
      'updateMultiSelectOnly',
      'updateAddAccountGroupId',
      'clearSelectedUserList',
      'updateFilterPublic'
    ]),
    clearInput() {
      this.updateFilterText('')
    },
    onSearchAccount(value) {
      this.updateFilterText(value)
    },
    onGetFilters(newfilters) {
      newfilters.forEach((filter) => {
        if (filter.key === 'public') {
          this.updateFilterPublic(filter.value)
        }
      })
    },
    onCleanFilters() {
      if (this.needEraser) {
        // 1. 清空 selectedGroupList
        // this.clearSelectedGroupList() // 清除範圍不要包含 groupTree
        // . 清空 filterText
        this.updateFilterText('')
        // . 清空 filterRoles
        this.cleanFilterRoles()
        // . 清空 filterUseStates
        this.cleanFilterUseStates()
        // . 清空 filterPublic
        this.updateFilterPublic(all)
      }
    },
    onSwitchMultiple(value) {
      this.multiSelectShow = value

      const editMode = value ? 'multiSelect' : 'editUser'
      this.updateEditMode(editMode)

      // 清除 MultiSelectOnly, SelectedUserList
      if (!value) {
        this.updateMultiSelectOnly(false)
        this.clearSelectedUserList()
      }
    },
    onCreateAccount() {
      if (this.multiSelectShow) {
        // TBD：增加 取消設定 確認; 因為會從 多選直接切換成新增, 如果沒存多選, 會全部被清掉
        // this.$refs.switch.$emit('switch', false)
        this.onSwitchMultiple(false)
      }

      this.updateEditMode('addUser')
      if (this.groupId) {
        this.updateAddAccountGroupId(this.groupId)
      } else {
        // 用 groupTree 第1層 當預設群組
        this.updateAddAccountGroupId(
          this.groupTreeList ? this.groupTreeList[0].id : ''
        )
      }
    },
    onGetRoleFilterText(filterText) {
      this.roleFilterText = filterText
    }
  },
  mounted() {
    this.onSwitchMultiple(false)
  },
  watch: {
    editMode(nVal) {
      this.multiSelectShow = nVal === 'multiSelect'
    },
    filterPublic(nVal) {
      const publicIdx = this.filters.findIndex(({ key }) => key === 'public')

      if (publicIdx >= 0) {
        this.filters[publicIdx].value = nVal
      }
    }
    // userFilters: {
    //   deep: true,
    //   handler: () => {
    //     console.log(`[watch.userFilters] userFilters:`, this.userFilters)
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.user-top {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: px2rem(12);
  border-bottom: 1px solid #4A5C78;
  color: #ffffff;

  $DropDownMaxW: calc(100vw * 0.20);
  .top-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: px2rem(8) 0 px2rem(8) px2rem(8);
    .input-select {
      // width: 80%;
      display: flex;
      // align-items: center;

      .text-search:deep {
        display: flex;
        align-items: center;
        width: px2rem(400);
        height: px2rem(48);
        margin-right: px2rem(12);
      }

      .select-role:deep {
        margin-right: px2rem(12);
        min-width: px2rem(120);
        max-width: $DropDownMaxW;

        .classify-select-wrap {
          .label-wrap {
            height: px2rem(48); // legacy
          }
          .dropdown-wrap {
            width: $DropDownMaxW;
          }
        }
      }

      .select-status {
        min-width: px2rem(120);
        max-width: $DropDownMaxW;
        margin-right: px2rem(12);
      }

      .select-more {
        min-width: px2rem(42);
        // height: 100%;
        margin-right: px2rem(12);
      }

      .eraser {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        margin-top: 0.4rem;
        padding: 0.5rem;
        height: px2rem(48);
        width: px2rem(48);
        &.disabled {
          opacity: 0.2;
          &:hover {
            background-color: unset;
            cursor: unset;
          }
        }
        &:hover {
          background-color: #4a5c7880; // 50%
          cursor: pointer;
        }
        img {
          height: px2rem(24);
          width: px2rem(24);
        }
      }

      .filter-result {
        margin-left: px2rem(12);
        padding-top: 1rem;
        font-size: px2rem(20);
      }
    }

    .top-filter-right {
      display: flex;
      justify-content: flex-end;

      // .filter-result {
      //   padding-top: px2rem(12);
      //   font-size: px2rem(20);
      // }
    }
  }

  .top-edit {
    display: flex;
    justify-content: flex-end;

    .create-account {
      margin-left: 1.5rem;
      cursor: pointer;

      .create-account-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 0.5rem;
        font-size: px2rem(24);
        background-color: #4a5c7880;

        &:hover {
          background-color: #4a5c78;
        }
        // &:lang(ja) {
        //   font-size: px2rem(14);
        // }

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

// .search span {
//   font-size: px2rem(24);
//   font-weight: 300;
//   color: #9D9D9D;
// }
</style>

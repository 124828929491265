<template>
  <transition name="modal">
    <div class="small-win-wrap" :style="{ '--winWidth': winWidth }">
      <div class="small-win-mask" @click="$emit('close')"></div>
      <div class="small-win-body">
        <div class="small-win-title" v-if="title">{{ title }}</div>
        <slot v-else name="small-win-title"></slot>
        <div class="small-win-content">
          <div class="small-win-detail">
            <slot name="small-win-detail"></slot>
          </div>
          <div class="small-win-footer">
            <button class="btn close" @click="$emit('close')">
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SmallWin',
  props: {
    title: {
      type: String,
      default: null
    },
    winWidth: {
      type: Number,
      default: 472
    }
  },
}
</script>

<style lang="scss" scoped>
$YGap: px2rem(32);
$ItemH: px2rem(36);
$WinW: calc(var(--winWidth) / 1280 * 100%);

* {
  box-sizing: border-box;
  // user-select: none;
}

.small-win-wrap {
  @include modal-wrap;
  z-index: 1;
  .small-win-mask {
    @include modal-bg;
  }
  .small-win-body {
    @include modal-content;
    left: calc((100vw - $WinW) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: $YGap auto;
    border: 1px solid $color_4A5C78;
    padding-top: $YGap;
    width: $WinW;
    height: calc(100vh - $YGap - $YGap/2);
    background-color: $color_282942;
    // background-color: #00f;

    .small-win-title {
      // margin-top: $YGap;
      margin-bottom: 1rem;
      font-size: 2rem; // 32px
      line-height: 1.5; // 48
      @include font_bold;
      color: $color_FFF;
    }
    .small-win-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // flex-grow: 1;
      width: 100%;
      min-height: calc(100% - px2rem(96)); // 扣除 title 高度: 32 + 48 + 16
      // background-color: #00f;
      .small-win-detail {
        flex-grow: 1;
        margin-bottom: px2rem(2);
        overflow-y: scroll;
        // background-color: #f00;
      }
      .small-win-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $color_4A5C78;
        padding: px2rem(12) 2rem;
        // background-color: #f00;
        .btn {
          border: 1px solid $color_FFC600;
          border-radius: 0.5rem;
          padding: 0 px2rem(24);
          width: px2rem(114);
          height: px2rem(44);
          color: $color_black;
          background-color: $color_FFC600;

          &:hover {
            background-color: $color_FFD133;
          }
        }
      }
    }
  }
}
</style>

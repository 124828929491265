<template>
  <div class="wrap-user2" :style="{ '--resize-leave': `${resize_leave}px` }">
    <div class="group-wrap" ref="group">
      <GroupTree class="group-tree-block" :showEdit="showEdit" />
      <button class="divider-bar" ref="divider" @mousedown="onStartResize">
        <div class="vertical-divider"></div>
      </button>
    </div>
    <div class="container-user">
      <UserTop class="container-user-top" :showEdit="showEdit" />
      <div class="container-user-detail" :showEdit="showEdit">
        <UserTable class="user-table" :showEdit="showEdit" />
        <div class="vertical-line"></div>
        <UserEdit class="user-detail" :showEdit="showEdit" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { console } from '@/utils/lib.js'
import { PermissionLevel } from '@/config/permissions.js'
import { all } from '@/config/account'

import GroupTree from './user/GroupTree.vue'
import UserTop from './user/UserTop.vue'
import UserTable from './user/UserTable.vue'
import UserEdit from './user/UserEdit.vue'

export default {
  name: 'User',
  components: {
    GroupTree,
    UserTop,
    UserTable,
    UserEdit
  },
  data() {
    return {
      isDev: console.get(),
      resizing: false,
      startX: 0,
      resize_leave: 2,
    }
  },
  computed: {
    ...mapState(['staff', 'permissionV2']),
    ...mapState('userinfo', ['roleList']),
    showEdit() {
      return this.permissionV2.account >= PermissionLevel.L2
    }
  },
  methods: {
    ...mapMutations('userinfo', [
      // DeviceGroupTree
      'updateGroupId',
      'clearSelectedGroupList',
      // UserTop
      'updateFilterText',
      'cleanFilterRoles',
      'cleanFilterUseStates',
      'updateFilterPublic',

      // Edit
      'updateEditMode',
      // Edit - Single
      'updateUserId',
      'updateSelectedUser',
      // Edit - New
      'updateAddAccountGroupId',
      // Edit - Multiple
      'clearSelectedUserList',
      'updateMultiSelectOnly',
    ]),
    init() {
      // init: 清除 store.userinfo 的資料

      // GroupTree
      this.updateGroupId('')
      this.clearSelectedGroupList()

      // UserTop
      this.updateFilterText('')
      this.cleanFilterRoles()
      this.cleanFilterUseStates()
      this.updateFilterPublic(all)

      // Edit
      this.updateEditMode('editUser')
      // Edit - Single
      this.updateUserId('')
      this.updateSelectedUser('')
      // Edit - New
      this.updateAddAccountGroupId('')
      // Edit - Multiple
      this.clearSelectedUserList()
      this.updateMultiSelectOnly(false)
    },
    onStartResize(event) {
      this.resizing = true
      this.startX = event.clientX

      document.addEventListener('mousemove', this.onResizeMove)
      document.addEventListener('mouseup', this.onStopResize)
    },
    onResizeMove(event) {
      if (this.resizing) {
        const fullGroupWrap = this.$refs.group
        const deltaX = event.clientX - this.startX
        let newWidth = fullGroupWrap.getBoundingClientRect().width + deltaX

        fullGroupWrap.style.width = `${Math.max(newWidth, this.resize_leave)}px`
        this.startX = event.clientX
      }
    },
    onStopResize() {
      this.resizing = false

      document.removeEventListener('mousemove', this.onResizeMove)
      document.removeEventListener('mouseup', this.onStopResize)
    }
  },
  created() {
    console.time(`User.created`)
    this.init()
    console.timeEnd(`User.created`)
  },
  mounted() {
    console.time(`User.mounted`)
    console.timeEnd(`User.mounted`)
  },
  // destroyed() {}
}
</script>

<style lang="scss" scoped>
$NavH: 70px;
$groupTreeW: 15%;
$topMaxH: px2rem(160); // fit-content;
$topMinH: px2rem(89);
$tableW: 60%;
$detailW: calc(100% - $tableW);
$dividerW: var(--resize-leave);

.wrap-user2 {
  display: flex;
  width: 100%;
  height: 100%;

  .group-wrap {
    display: flex;
    width: $groupTreeW;
    .group-tree-block {
      width: calc(100% - $dividerW);
      overflow: hidden;
    }

    .divider-bar {
      height: 100%;
      width: calc($dividerW * 1.5);
      background-color: transparent;
      cursor: col-resize;

      // &:hover {}

      .vertical-divider {
        @include vertical_divider($color_4A5C78, $dividerW);
      }
    }
  }

  .container-user {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // width: calc(100% - $groupTreeW);
    height: calc(100vh - $NavH);

    .container-user-top {
      min-height: $topMinH;
      max-height: $topMaxH;
    }

    .container-user-detail {
      display: flex;
      width: 100%;
      height: calc(100% - $topMinH + px2rem(8));

      &[showEdit='true'] {
        height: calc(100% - $topMaxH + px2rem(13));
      }

      .user-table {
        width: calc($tableW - 1px);
        // background-color: #f00;
      }
      .vertical-line {
        @include vertical_divider($color_4A5C78, 2px);
      }
      .user-detail {
        width: calc($detailW - 1px);
        // background-color: green;
      }
    }
  }
}
</style>

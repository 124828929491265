<template>
  <SmallWin class="win-wrap" :title="roleName" @close="$emit('close')">
    <template #small-win-detail>
      <div
        v-for="(item, idx) of permissionList"
        :key="`${item.param}_${idx}`"
        class="permission-item-wrap"
      >
        <div v-if="!item.ui" class="permission-title-wrap">
          {{ $t(`role_${item.param}`) }}
        </div>
        <div
          v-else
          class="permission-detail-wrap"
          :class="{ last: idx >= permissionList.length - 1 }"
        >
          <div class="permission-name">
            {{ $t(`role_${item.param}`) }}
          </div>
          <div class="permission-value">
            <span class="top-scope" v-if="isTopScope(item)">
              <img src="@/assets/icons/group-icon.svg" alt="" />
              <span>{{ permissionValue(item) }}</span>
            </span>
            <span v-else>{{ permissionValue(item) }}</span>
          </div>
        </div>
      </div>
    </template>
  </SmallWin>
</template>

<script>
import SmallWin from '@/components/Base/SmallWin.vue'
import { permissionList } from '@/config/permissions.js'

export default {
  name: 'PermissionInfo',
  components: { SmallWin },
  props: {
    roleName: {
      type: String,
      default: null
    },
    permissions: {
      type: Object,
      default: null
    },
    groupInfoList: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      permissionList
    }
  },
  computed: {
    topScope() {
      return this.permissions['topScope']
    }
  },
  watch: {},
  methods: {
    isTopScope(item) {
      return item.param === 'topScope'
    },
    permissionValue(item) {
      if (item.param === 'topScope') {
        const group = this.groupInfoList.find(
          (_g) => Number(_g.id) === this.topScope
        )
        return group ? group.name : this.$t('role_topScop_default')
      }

      const value = this.permissions[item.param]
      // console.log(`permissionValue] ${ item.param} value:`, this.$t(item.tooltip.level[value]))
      return this.$t(item.tooltip.level[value])
    }
  },
  created() {},
  mounted() {
    // console.log(`permissions:`, this.permissions)
  },
  destroyed() {}
}
</script>

<style lang="scss" scoped>
$ItemNameW: 40%;
$ItemValueW: calc(100% - $ItemNameW);

* {
  box-sizing: border-box;
  // user-select: none;
}
.permission-item-wrap {
  display: flex;
  padding-left: px2rem(32);
  padding-right: px2rem(16);
  width: 100%;
  overflow-y: scroll;

  .permission-title-wrap {
    border-bottom: 1px solid $color_divider;
    padding-left: px2rem(12);
    padding-top: px2rem(12);
    padding-bottom: px2rem(12);
    width: 100%;
    @include font_bold;
    color: $color_FFE99F;
  }

  .permission-detail-wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color_divider;
    padding-left: px2rem(12);
    padding-top: px2rem(12);
    padding-bottom: px2rem(12);
    width: 100%;
    color: $color_FFF;

    &.last {
      border-color: transparent;
    }

    .permission-name {
      width: $ItemNameW;
    }
    .permission-value {
      width: $ItemValueW;
      .top-scope {
        display: flex;
        align-items: center;
        color: $color_FFC600;
        img {
          width: 1rem;
          height: 1rem;
        }
        span {
          margin-left: 0.5rem;
        }
      }
    }
  }

  // .divider {
  //   margin-top: px2rem(12);
  //   margin-bottom: px2rem(12);
  //   @include horzontal_divider;
  // }
}
</style>
